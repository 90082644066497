import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Icon from "../../../components/Icon";
import axios from "axios";
import jsPDF from "jspdf";
import { Datepicker } from "@mobiscroll/react";
import DivCard from "../../../components/CardDiv";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { format } from "date-fns";

const items = [
  {
    title: "No of users",
    icon: "user",
    background: "#A694F040",
  },
  {
    title: "Active users with Daleel ",
    icon: "personcheck",
    background: "#A694F040",
  },
  {
    title: "Active users without Daleel ",
    icon: "person",
    background: "#A694F040",
  },
  {
    title: "Number of sold cards",
    icon: "card",
    background: "#A694F040",
  },
  {
    title: "Number of share Info",
    icon: "shareinfo",
    background: "#A694F040",
  },
  {
    title: "Number of companies",
    icon: "company",
    background: "#A694F040",
  },
  {
    title: "Total sales",
    icon: "allconnection",
    background: "#A694F040",
  },
  {
    title: "Number of app download",
    icon: "mobile",
    background: "#A694F040",
    counter: "0",
  },
  {
    title: "Number of click social media",
    icon: "globale",
    background: "#A694F040",
    counter: "0",
  },
  {
    title: "Number of click Facebook",
    icon: "facebook",
    background: "#A694F040",
    counter: "0",
  },
  {
    title: "Number of click Instagram",
    icon: "instagram",
    background: "#A694F040",
    counter: "0",
  },
  {
    title: "Number of click LinkedIn",
    icon: "linkedin",
    background: "#A694F040",
    counter: "0",
  },
  {
    title: "Number of click x",
    icon: "x",
    background: "#A694F040",
    counter: "0",
  },
];

const Overview = ({ className }) => {
  const [itemsData, setItemsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState([]);

  // Fetch data function
  const fetchData = (startDate, endDate) => {
    setLoading(true);
    axios
      .get("/sanctum/csrf-cookie")
      .then((response) => {
        let url = "api/DashboardCounts";
        // If a date range is selected, append it to the API URL
        if (startDate && endDate) {
          const formattedStartDate = format(new Date(startDate), "yyyy-MM-dd");
          const formattedEndDate = format(new Date(endDate), "yyyy-MM-dd");
          url += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        }
        axios
          .get(url)
          .then((res) => {
            if (res.status === 200) {
              const data = res.data.data;
              const updatedItems = items.map((item) => {
                switch (item.title) {
                  case "No of users":
                    return { ...item, counter: data.users };
                  case "Active users with Daleel ":
                    return { ...item, counter: data.activate };
                  case "Active users without Daleel ":
                    return { ...item, counter: data.Notactivate };
                  case "Number of sold cards":
                    return { ...item, counter: data.sold };
                  case "Number of share Info":
                    return { ...item, counter: data.share };
                  case "Number of companies":
                    return { ...item, counter: data.company };
                  case "Total sales":
                    return { ...item, counter: data.sales };
                  default:
                    return item;
                }
              });
              setItemsData(updatedItems);
              setError(null);
            }
          })
          .catch((error) => {
            setError("Failed to fetch data.");
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setError("Failed to fetch CSRF token.");
        setLoading(false);
      });
  };

  // Fetch data initially
  useEffect(() => {
    fetchData();
  }, []);

  // Fetch data whenever selectedDateRange changes
  useEffect(() => {
    if (selectedDateRange) {
      const [startDate, endDate] = selectedDateRange;
      fetchData(startDate, endDate);
    } else {
      fetchData(); // Fetch data for all time if no date range selected
    }
  }, [selectedDateRange]);

  // Extract PDF Function
  const handleExtractPDF = () => {
    const doc = new jsPDF();
    itemsData.forEach((item, index) => {
      doc.text(`${item.title}: ${item.counter}`, 10, 10 + index * 10);
    });
    doc.save("overview.pdf");
  };

  // Prevent page refresh on PDF download
  const handleDownloadPDF = (event) => {
    event.preventDefault();
    handleExtractPDF();
  };

  return (
    <>
      <DivCard
        className={cn(styles.card, className)}
        title="Overview"
        classTitle="title-purple"
        head={
          <div className={styles.cardbtns}>
            <button
              className={cn("button", styles.button)}
              onClick={handleDownloadPDF}
            >
              Extract
            </button>
            <div className={styles.datebtn}>
              <Datepicker
                controls={["calendar"]}
                select="range"
                value={selectedDateRange}
                selectMultiple={true}
                touchUi={true}
                inputComponent="input"
                inputProps={{ placeholder: "All time" }}
                onChange={(event) => setSelectedDateRange(event.value)}
                onCancel={(event) => setSelectedDateRange([])}
              />
            </div>
          </div>
        }
      >
        <div className={`row ${styles.overview}`}>
          <div className={styles.list}>
            {itemsData.map((x, index) => (
              <div
                className={`col-md-4 ${styles.item}`}
                key={index}
                style={{ backgroundColor: x.background }}
              >
                <div className={styles.icon}>
                  <Icon name={x.icon} size="24" />{" "}
                </div>

                <div className={styles.line}>
                  <div className={styles.details}>
                    <div className={styles.category}>{x.title}</div>
                    <div className={styles.counter}>{x.counter}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </DivCard>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
