import React from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";
import { imageUrl } from "../../../config";

const Profile = ({ Links }) => {
  return (
    <div>
      {Links.map((x, index) => (
        <div className={styles.profile}>
          <div className={styles.details}>
            <div className={styles.avatar}>
              {x.image ? <img src={`${imageUrl}/${x.image}`} alt="Avatar" /> : <img src="/images/logo-light.png" alt="Avatar" />}
            </div>
            <div className={styles.wrap}>
              <div className={cn("h4", styles.man)}>{x.name ?? 'x'}</div>
              <div className={styles.info}>{x.sector ?? 'x'}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Profile;