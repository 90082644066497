import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import './Maiprofile.css';
import axios from 'axios';
import Popup from "reactjs-popup";
import { imageUrl } from '../../config';

function MainProfile() {
  const modal = { background: "#fff" };
  const close = { background: "#000" };
  const arrowStyle = { visibility: "#hidden" };

  // Form Data
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    sector: '',
    expertise: '',
    profile_id: '',
  });

  // Params
  const { id } = useParams();

  // States
  const [profile, setProfile] = useState([]);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [isProfileNotFound, setIsProfileNotFound] = useState(false); // State to track 404 status

  // Ref for the button
  const shareInfoCloseRef = useRef(null);

  // Axios
  useEffect(() => {
    const getProfile = async () => {
      await axios.get(`api/main-profile/${id}`).then((response) => {
        console.log('response', response);
        setProfile(response.data.data);
      }).catch((error) => {
        console.log('error', error);

        if (error.response.status == 404) {
          setIsProfileNotFound(true);
          handleOpen();
        }
      });
    }
    getProfile();
  }, [id]);

  // Handlers to manage scroll 
  const handleOpen = () => {
    document.body.classList.add('no-scroll');
    const blur = document.body.querySelector('#blur');
    blur.setAttribute('class', 'blur');
  };

  const handleClose = () => {
    document.body.classList.remove('no-scroll');
    const blur = document.body.querySelector('#blur');
    blur.removeAttribute('class', 'blur');
  };

  // Handle Change
  const handleChange = async (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // Handle Submit
  const handleSubmit = async (e, id) => {
    e.preventDefault();
    form.profile_id = id;
    try {
      await axios.post('api/AddShareInfo', form).then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          setErrors([]);

          // Trigger the button click programmatically
          if (shareInfoCloseRef.current) {
            shareInfoCloseRef.current.click();
            setSuccess(false);
          }
        }
      });
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  // Function to create and download vCard
  const downloadVCard = (contact) => {
    // Initialize vCard content with basic details
    let vCardContent = `BEGIN:VCARD
  VERSION:3.0
  FN:${contact.name}
  ORG:${contact.company}\n`;

    // Loop through links and add to vCard content based on type
    contact.links?.forEach(link => {
      if (link.link_type === 'email') {
        vCardContent += `EMAIL:${link.url}\n`;
      } else if (link.link_type === 'phone') {
        vCardContent += `TEL:${link.url}\n`;
      }
    });

    // Finalize vCard content
    vCardContent += `END:VCARD`;

    // Create a blob and download it as a .vcf file
    const blob = new Blob([vCardContent], { type: 'text/vcard' });
    const urlObject = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlObject;
    link.download = `${contact.name}.vcf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(urlObject);
  };

  return (
    <div className='main-container'>
      <div id='blur'></div>
      {/* First Popup for 404 Error */}
      <Popup open={isProfileNotFound} position="right center" onOpen={handleOpen} onClose={handleClose} className='testing'>
        <div className='for-error flex-col-center'>
          <span className='error-message'>Sorry, but no profile was found for this URL. Please contact support.</span>
          <div className='error-contact flex-row-center'>
            <a href="tel:+962798178491" className='footer-contact'>
              <i className="bi bi-telephone footer-icon"></i>
              <span>+962798178491</span>
            </a>

            <a href="mailto:daleel.nfc@gmail.com" className='footer-contact'>
              <i className="bi bi-envelope footer-icon"></i>
              <span>daleel.nfc@gmail.com</span>
            </a>
          </div>
        </div>
      </Popup>

      {/* User Information */}
      {profile.map((item, index) => (
        <>
          {/* <img src={item.banner ? `${imageUrl}/${item.banner}` : '/images/content/bg-shop.png'} className='background-mobile' alt='background' /> */}
          <div className='portfolio' key={index}>
            <div className='images'>
              <div className='background'>
                <img src={item.banner ? `${imageUrl}/${item.banner}` : '/images/content/bg-shop.png'} alt='background' />
              </div>

              <div className='profile'>
                <img src={item.image ? `${imageUrl}/${item.image}` : '/images/logo-light.png'} alt='Profile' />
              </div>
            </div>

            <div className='info'>
              <div className='about flex-col-center'>
                <strong className='text-custom'>{item.name}</strong>
                <span>{item.sector}</span>
                <span className='light'>{item.bio}</span>
              </div>
              <div className='contact'>
                {item.links && item.links.length > 0 && (
                  <div className='contact-buttons flex-row-center'>
                    {item.links.some(link => link.link_type === 'phone') && (
                      <a href={`tel:+${item.links.find(link => link.link_type === 'phone').url}`} className='buttons buttons-circle'>
                        <i className="bi bi-telephone"></i>
                      </a>
                    )}
                    {item.links.some(link => link.link_type === 'email') && (
                      <a href={`mailto:${item.links.find(link => link.link_type === 'email').url}`} className='buttons buttons-circle'>
                        <i className="bi bi-envelope"></i>
                      </a>
                    )}
                  </div>
                )}

                {/* Shareinfo */}
                <div className='contact-buttons flex-col-center'>
                  {item.links.length > 0 && (
                    <button
                      className='buttons buttons-rectangle'
                      onClick={() => downloadVCard({
                        name: item.name ?? '',
                        company: item.company,
                        links: item.links
                      })}>
                      Save Contact
                    </button>
                  )}

                  <Popup trigger={<button className='buttons buttons-rectangle-2' style={{ backgroundColor: '#222332' }}>Share Info</button>}
                    modal={modal}
                    close={close}
                    arrowStyle={arrowStyle}
                    onOpen={handleOpen} onClose={handleClose}>

                    {(close) => (
                      <div className='shareinfo'>
                        <button ref={shareInfoCloseRef} className='shareinfo-close' onClick={close}><i className="bi bi-x-circle"></i></button>
                        <form className='shareinfo-form' onSubmit={(e) => handleSubmit(e, item.id)}>
                          <input type='text' name='name' placeholder='Name' onChange={handleChange} />
                          <span className='shareinfo-error'>{errors ? errors.name : ''}</span>
                          <input type='email' name='email' placeholder='Email' onChange={handleChange} />
                          <span className='shareinfo-error'>{errors ? errors.email : ''}</span>
                          <input type='tel' name='phone' placeholder='Phone' onChange={handleChange} />
                          <span className='shareinfo-error'>{errors ? errors.phone : ''}</span>
                          <input type='text' name='sector' placeholder='Sector' onChange={handleChange} />
                          <span className='shareinfo-error'>{errors ? errors.sector : ''}</span>
                          <input type='text' name='company' placeholder='Company' onChange={handleChange} />
                          <span className='shareinfo-error'>{errors ? errors?.company : ''}</span>
                          <span className={`${success && 'valid'}`}>{success && 'Created Shareinfo Successfully'}</span>
                          <button type='submit' className='buttons buttons-rectangle'>Save</button>
                        </form>
                      </div>
                    )}
                  </Popup>
                </div>
                {/* Links */}
                <div className='contact-links'>
                  <header className='header'>Links</header>
                  <div className='links'>
                    {item.links && item.links.length > 0 ? (
                      item.links.map((link) => (
                        <a href={`${link.link_type === 'email' ? `mailto:${link.url}` : link.link_type === 'phone' ? `tel:+${link.url}` : link.url}`}
                          className='link'
                          key={link.id}
                        >
                          <div className='head-link'>
                            <i className={`bi bi-${link.link_type === 'email' ? 'envelope' : link.link_type === 'phone' ? 'telephone' : link.link_type === 'url' ? 'link-45deg' : ''} link-icon `}></i>
                            <div className='link-navigator'>
                              <span className='url'>{link.name}</span>
                              <i className="bi bi-chevron-right"></i>
                            </div>
                          </div>
                        </a>
                      ))
                    ) : (
                      <span className='message message-link'>No links found....</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div></>
      ))}

      {/* Mobile App For Daleel */}
      <div className="about-app">
        <div className="app-head">
          Daleel Mobile App Available Now
          <span className="app-head-image"></span>
        </div>

        <span className='sub-head'>
          Find your info easily and save all your new connections in one place.
        </span>

        <div className='app-buttons flex-row-center'>
          <a href='https://play.google.com/store/apps/details?id=com.daleelnfc.app&pcampaignid=web_share'><img src="/images/mainprofile/Google-Play.svg" alt='Google Play' /></a>
          <a href='https://apps.apple.com/eg/app/daleelnfc/id6504637190'><img src="/images/mainprofile/App-Store.svg" alt='App Store' /></a>
          <a href='https://wa.me/+966557891715'><img src="/images/mainprofile/Get-Card.svg" alt='Get Card' /></a>
        </div>

        <div className='rounded'>
          <img src="/images/mainprofile/frame1.png" alt='Frame 1' className='frame fr1' />
          <img src="/images/mainprofile/frame2.png" alt='Frame 2' className='frame fr2' />
        </div>
      </div>

      {/* Footer */}
      <div className='footer flex-between'>
        <div className='box'>
          <img src="/images/logo-dark.png" className='icon' alt='Logo' />
          <div className='content'>
            <p>Find your info easily and save all your new connections in one place.</p>
          </div>
        </div>
        {/* Contact */}
        <div className='box'>
          <strong>Contact</strong>
          <div className='content content-2 flex-col-center'>
            <a href="tel:+962798178491" className='footer-contact'>
              <i className="bi bi-telephone footer-icon"></i>
              <span>+962798178491</span>
            </a>
            <a href="mailto:daleel.nfc@gmail.com" className='footer-contact'>
              <i className="bi bi-envelope footer-icon"></i>
              <span>daleel.nfc@gmail.com</span>
            </a>
          </div>
        </div>
        {/* Social Media Links */}
        <div className='box'>
          <strong>Social Media</strong>
          <div className='content content-3'>
            <a href='https://www.facebook.com/profile.php?id=61564134667348&mibextid=rS40aB7S9Ucbxw6v'><i className="bi bi-facebook"></i></a>
            <a href='https://www.instagram.com/daleelnfc?igsh=ZGlwanljYXhzN3p2'><i className="bi bi-instagram"></i></a>
            <a href='https://wa.me/+966557891715'><i className="bi bi-whatsapp"></i></a>
            <a href="mailto:daleel@gmail.com"><i className="bi bi-envelope"></i></a>
          </div>
        </div>

        {/* Download Links */}
        <div className='box'>
          <strong>Download the App</strong>
          <div className='content content-4'>
            <div className='downloads-images'>
              <a href='https://play.google.com/store/apps/details?id=com.daleelnfc.app&pcampaignid=web_share'><img src="/images/mainprofile/Google-Play.svg" alt='Google Play' /></a>
              <a href='https://apps.apple.com/eg/app/daleelnfc/id6504637190'><img src="/images/mainprofile/App-Store.svg" alt='App Store' /></a>
              <a href='https://wa.me/+966557891715'><img src="/images/mainprofile/Get-Card.svg" alt='Get Card' /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainProfile;