import React, { useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Shop.module.sass";
import Profile from "./Profile";
import MyLinks from "./MyLinks";
import Connections from "./Connection";
import ShareInfo from "./ShareInfo";
import { ScaleLoader } from "react-spinners";
import { useEffect } from "react";
import axios from "axios";
import DivCard from "../../components/CardDiv"
import { imageUrl } from "../../config";

const navigation = [
  "My Links",
  "Connections",
  "Share Info",
];

const intervals = ["Most recent", "Most new", "Most popular"];

const Shop = () => {
  const { id } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const [mappedData, setMappedData] = useState([]);
  const [links, setLinks] = useState([]);
  const [share, setShare] = useState([]);
  const [connections, setConnections] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    axios.get(`api/GetUserProfile/${id}`).then((res) => {
      if (res.data.status === 200) {
        setLinks(res.data.data);
        const mapped = res.data.data.map((link) => {
          return {
            banner: link.banner,
          };
        });
        setMappedData(mapped);
      }
      setloading(false);
    });
  }, [id]);

  useEffect(() => {
    axios.get(`api/GetuserShareInfo/${id}`).then((res) => {
      if (res.data.status === 200) {
        setShare(res.data.data);
      }
      setloading(false);
    });
  }, [id]);

  useEffect(() => {
    axios.get(`api/ViewUserFollow/${id}`).then((res) => {
      if (res.data.status === 200) {
        setConnections(res.data.data);
      }
      setloading(false);
    });
  }, [id]);

  return (
    <>
      {loading ? (
        <div className={styles.shop}>
          <div className={styles.background}>
            {mappedData.banner ? <img src={`${imageUrl}/${mappedData.banner}`} alt="Avatar" /> : <img src="/images/content/bg-shop.png" alt="Avatar" />}
          </div>
          <DivCard className={styles.card}>
            <center>
              <ScaleLoader color="#8E59FF" />
            </center>
          </DivCard>
        </div>
      ) : (
        <div className={styles.shop}>
          <div className={styles.background}>
            {mappedData.banner ? <img src={`${imageUrl}/${mappedData.banner}`} alt="Avatar" /> : <img src="/images/content/bg-shop.png" alt="Avatar" />}
          </div>

          <DivCard className={styles.card}>
            <Profile Links={links} />
            <div className={styles.control}>
              <div className={styles.nav}>
                {navigation.map((x, index) => (
                  <button className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                    onClick={() => setActiveIndex(index)}
                    key={index}>
                    {x}
                  </button>
                ))}
              </div>
              <div className={styles.dropdownBox}>
              </div>
            </div>

            <div className={styles.wrap}>
              {activeIndex === 0 && <MyLinks Links={links} id={id} />}
              {activeIndex === 1 && (
                <>
                  <div className={styles.followers}>
                    <Connections connections={connections} />
                  </div>
                </>
              )}
              {activeIndex === 2 && (
                <>
                  <div className={styles.followers}>
                    <ShareInfo share={share} />
                  </div>
                </>
              )}
            </div>

          </DivCard>
        </div>
      )}
    </>
  );
};

export default Shop;