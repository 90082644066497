import React, { useState } from "react";
import styles from "./Row.module.sass";
import ModalProduct from "../../../../components/ModalProduct";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { imageUrl } from "../../../../config";


const Row = ({ up, item }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const history = useNavigate();

  const deleteAccount = (e) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You will Delete this Account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#8E59FF",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel !",
      closeOnConfirm: false,
      closeOnCancel: false
    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        // If user confirms deletion, proceed with deletion
        axios.get("/sanctum/csrf-cookie").then((response) => {
          axios.post(`api/ArchiveUser/${item.id}`).then((res) => {
            if (res.data.status === 200) {
              // Display success message
              Swal.fire("Deleted!", "Account has been deleted.", "success");
              window.location.reload();
            }
          });
        });
      } else {
        // If user cancels, show cancellation message
        Swal.fire("Cancelled", "Your Account is safe :)", "error");
      }
    });
  };

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              {item.profile.image ? <img src={`${imageUrl}/${item.profile.image}`} alt="Avatar" /> : <img src="/images/logo-light.png" alt="Avatar" />}
            </div>
            <div className={styles.details}>
              <div className={styles.man}>
                <div className={styles.label}>Name</div>
                {item.profile.name}</div>
              <div className={styles.login}>
                <div className={styles.label}>Role</div>
                {item.role}</div>
            </div>
          </div>
          <div className={styles.details}></div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Email</div>
          {item.email}
        </div>

        <Link to={`/EditAdminAccount/${item.id}`} className={`${styles.col} ${styles.editbtn}`}>Edit</Link>
        <div className={`${styles.col} ${styles.deletebtn}`} onClick={deleteAccount}>Delete</div>

      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
